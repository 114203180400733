<template>
  <div class="row" v-if="Object.keys(requisition).length !== 0">
    <requisition-hub-detail :requisition="requisition"></requisition-hub-detail>
    <requisition-header-detail :requisition="requisition" :spinning="spinning" :delayTime="delayTime"></requisition-header-detail>
    <requisition-header-status :current="current" :requisition="requisition"></requisition-header-status>
    <div class="col-md-12" v-if="requisition.hub_transfer_chalan_form_picture">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="float-left text-center">
              <a :href="requisition.hub_transfer_chalan_form_picture_url" target="_blank" download>
                <img :src="requisition.hub_transfer_chalan_form_picture_url" alt="" class="rounded img-thumbnail" width="80" height="80"/>
                <div class="chalan-form-pictre-footer">Figure: Hub Received Products Chalan Form</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <form action="">
            <table class="table table-hover table-bordered">
            <thead>
            <tr>
              <th>Product Name</th>
              <th>Product Unit</th>
              <th>Warehouse Stock</th>
              <th>Approved Comment</th>
              <th>Approved Qty</th>
              <th>Transfer Qty</th>
              <th>Transferer Comment</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(requisition_item, index) in requisition_items" :key="index">
              <input type="hidden" :id="'product_id_'+requisition_item.id"  v-model="requisition_item.product_id">
              <input type="hidden" :id="'accepted_amount_'+requisition_item.id"  v-model="requisition_item.accepted_amount">
              <td>{{ requisition_item.product.name }}</td>
              <td>{{ requisition_item.product.unit }}</td>
              <td>{{ requisition_item.product.available_quantity }}</td>
              <td>{{ requisition_item.comment || 'No Comment' }}</td>
              <td>{{ requisition_item.accepted_amount }}</td>
              <td>
                <input type="text" :disabled="(requisition_item.accepted_amount == requisition_item.view_transfer_amount ? requisition_item.view_transfer_amount : 0 || requisition_item.accepted_amount == 0) ? true : false" class="form-control form-control-sm"  v-model="requisition_items[index].input_accepted_amount" v-validate="{ required:true, max: 5, regex: /^[0-9]*$/ }"  :id="'transfer_amount_'+requisition_item.id" @change="transferAmount($event.target.value, index)"  :name="'transfer_amount_' + index">
                <span class="error invalid-feedback" v-if="errors.has('transfer_amount_'+index)"><span style="font-weight: 600">{{ requisition_item.product.name }}</span> transfer amount is required</span>
              </td>
              <td>
                <input :disabled="(requisition_item.accepted_amount == requisition_item.view_transfer_amount ? requisition_item.view_transfer_amount : 0 || requisition_item.accepted_amount == 0) ? true : false" class="form-control form-control-sm"  v-model="requisition_items[index].transferer_comment" :id="'transferer_comment_'+requisition_item.id" :name="'transferer_comment_' + index">
              </td>
            </tr>
            </tbody>
          </table>
            <a-skeleton active :loading="loading"></a-skeleton>
            <a-button class="btn btn-success waves-effect waves-light mr-2" :loading="requisitionTransferBtn" @click.prevent="update"><i class="fa fa-paper-plane mr-1"/>  Transfer Stock</a-button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import moment from 'moment'
import $ from 'jquery'
import requisitionHeaderDetail from '@/views/requisition/partial/requisition_header_detail'
import requisitionHeaderStatus from '@/views/requisition/partial/requisition_header_status'
import requisitionHubDetail from '@/views/requisition/partial/requisition_hub_detail'

export default {
  name: 'transfer_view',
  components: { requisitionHeaderDetail, requisitionHeaderStatus, requisitionHubDetail },
  data() {
    return {
      requisition: {},
      requisition_items: [],
      requisition_item_ids: [],
      loading: false,
      show: false,
      requisitionTransferBtn: false,
      spinning: false,
      delayTime: 500,
      current: 0,
    }
  },
  mounted() {
    this.getRequisition()
  },
  methods: {
    customDate(date) {
      return moment(date).format('LL')
    },
    getRequisition() {
      const requisitionId = this.$route.params.requisition_id
      this.loading = true
      this.spinning = true
      apiClient.get('api/requisition/hub/closed/view/' + requisitionId)
        .then(response => {
          this.loading = false
          this.spinning = false
          const data = response.data
          this.requisition = data.requisition
          this.requisition_items = data.requisition_items
          this.requisition_item_ids = data.requisition_item_ids
          this.current = data.max_item_requisition_status
        })
        .catch(error => {
          console.log(error)
        })
    },
    transferAmount($event, index) {
      const transferAmount = $event
      const acceptedAmount = parseFloat(this.requisition_items[index].accepted_amount || 0)
      const previousAmount = parseFloat(this.requisition_items[index].view_transfer_amount || 0)
      const total = parseFloat(transferAmount) + previousAmount
      if ((total > acceptedAmount) || acceptedAmount !== parseFloat(transferAmount)) {
        this.$notification.error({
          message: 'Transfer amount must be equal to accepted amount',
        })
        this.requisition_items[index].input_accepted_amount = ''
      }
    },
    remainingTransfer(acceptedAmount, viewTransferAmount) {
      return (parseFloat(acceptedAmount) || 0) - (parseFloat(viewTransferAmount) || 0)
    },
    update() {
      this.$validator.validate().then(valid => {
        if (valid) {
          const requisitionTransferArray = []
          this.requisition_item_ids.forEach(id => {
            const productIdDiv = '#product_id_' + id
            const productId = $(productIdDiv).val()

            const acceptedAmountDiv = '#accepted_amount_' + id
            const acceptedAmount = $(acceptedAmountDiv).val()

            const transferAmountDiv = '#transfer_amount_' + id
            const transferAmount = $(transferAmountDiv).val()

            const transfererCommentDiv = '#transferer_comment_' + id
            const transfererComment = $(transfererCommentDiv).val()
            if (transferAmount) {
              const requisitionTransfer = {
                hub_id: this.requisition.hub_id,
                accepted_amount: acceptedAmount,
                product_id: productId,
                requisition_item_id: id,
                transfer_amount: transferAmount,
                transferer_comment: transfererComment,
              }
              requisitionTransferArray.push(requisitionTransfer)
            }
          })

          if (requisitionTransferArray.length === 0) {
            this.$notification.error({
              message: 'Insert at least 1 item',
            })
            return
          }
          this.requisitionTransferBtn = true
          const formData = new FormData()
          formData.append('requisition_transfer_items', JSON.stringify(requisitionTransferArray))
          const requisitionId = this.$route.params.requisition_id
          apiClient.post('api/requisition/hub/transfer/update/' + requisitionId, formData, { headers: { 'Content-Type': 'multipart/form-data', 'Content-type': 'application/json' } })
            .then(response => {
              this.requisitionTransferBtn = false
              if (!response.data.error) {
                this.$notification.success({
                  message: response.data.message,
                })
                // requisitionTransferArray = []
                this.getRequisition()
              } else {
                this.$notification.error({
                  message: response.data.message,
                })
              }
            }).catch(error => {
              this.updateRequisition = false
              this.show = true
              this.validation_errors = error.response.data.errors
              this.hide()
            })
        }
      })
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style scoped>
table {
  position: relative;
}

th {
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 1;
}
.chalan-form-pictre-footer{
  color: #595c97;
  font-size: 16px;
  line-height: 32px;
}
</style>
